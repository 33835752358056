/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');

        var target = $('[data-nav]')
        if ($(this).hasClass('active')){
            target.slideDown(300);
        } else {
            target.slideUp(300);
        }

        $('[data-navTo]').on('click', function(){
            if ($(window).width() <= 1024) {
                $('[data-nav-trigger]').removeClass('active');
                $('[data-nav]').slideUp(300);
                return false;
            }
        });
    });
});
